import React, { useState } from 'react';
import NavigationSystem from './Routes/index'
function App() {

  
  return (
<NavigationSystem/>
   
);
}


export default App;